import { faAngleDown, faAngleUp, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate,Location } from "react-router-dom";
import { dissmiss, getMainMenuContent, putWelcomeMessages, setPageSetting, toggleSideBar } from "../../actions";
import { isMobileDevice } from "../../constants";
import { RootState } from "../../reducers";
import { EventUtils, ObjectUtils, USER_TOKEN } from "../../utils";
import { ModalComponent, WarningMessageModal } from "../Modal";
// import { ModalComponent, WarningMessageModal } from "../Modal";
// import Checkmark from "../../assets/icons/checkmark.svg";

//Pawan: Need to Review for pagsetting
export const MenuComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuKeys, setMenuKeys] = useState<any>({});
  const mainMenu = useSelector((state: RootState) => state.common.contentMainMenu);
  const { contentFooter, putWelcomeResponse, showWarningMessage, screens } = useSelector((state: RootState) => state.common);
  const { isOpen } = useSelector((state: RootState) => state.auth);
  const welcomeContent = putWelcomeResponse?.contentResource || {};
  // console.log("welcomeContent: ", welcomeContent);

  const { pageSetting, nextEnrolStep } = useSelector((state: RootState) => state.event);
  const headerImages = useSelector((state: RootState) => state.common.contentHeader);
  const footerContent = contentFooter?.contentResource?.contents;
  const language = headerImages?.contentResource?.contents;
  const contents = welcomeContent?.contents || {};
  // const welcomeContent = contentWelcome?.result;
  // const copyrightMessage = contentFooter?.copyright;
  const tabIndexValue = 0;

  const index = screens && screens.length > 0 ? screens.findIndex((item: { name: string }) => item.name === "PERSONALINFORMATION") : -1;

  const allowWarningMessageFlag = showWarningMessage && ((nextEnrolStep !== null && nextEnrolStep.nextEvent !== undefined && nextEnrolStep.nextEvent !== null) || index >= 0) ? true : false;

  const paths = location?.pathname?.slice(1);
  const search = location?.search || "";
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [actionLink, setActionLink] = useState<string>("");
  const [selectMenuItem, setSelectMenuItem] = useState<string>("");
  const [isNormalLogOff, setIsNormalLogOff] = useState<boolean>(true);
  const isEmpty = contents && contents.length > 0?true:false;

  const [logOutPopUp,setlogOutPopUp] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [showHelpContent, setShowHelpContent] = useState<boolean>(false);
  // const [helpTitle, setHelptitle] = useState<string>("");
  // const [helpAriaTitle, setHelpAriaTitle] = useState<string>("");

  const lblLogoutDialogTitle = ObjectUtils.extractLabelFromObject(contents, "lblLogoutDialogTitle");
  const logoutConfirmMessage = ObjectUtils.extractLabelFromObject(contents, "logoutConfirmMessage");
  const btnCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancelEnrollment");
  const btnOk = ObjectUtils.extractLabelFromObject(contents, "btnOK");

  // const isSecondMenu = localStorage.getItem("isReview") || localStorage.getItem("lifeEvent") ? true : false;

  const renderMenuObject = useCallback(
    (response: any) => {
      const keys = Object.keys(response);
      const updatedMenu = { ...response };
      keys?.map((key) => {
        updatedMenu[key] = false;
        if (response[key]?.actinLink === "") {
          for (const subItem in response[key]?.subMenus) {
            if (paths === response[key]?.subMenus[subItem]?.actinLink) {
              updatedMenu[key] = true;
            }
          }
        }
      });

      setMenuKeys(updatedMenu);
    },
    [paths]
  );

  const loadMainMenu = useCallback(async () => {
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      dispatch(await getMainMenuContent(true));
      !putWelcomeResponse && dispatch(await putWelcomeMessages());
    } else {
      dispatch(await getMainMenuContent(false));
    }
    mainMenu && renderMenuObject(mainMenu);
  }, [dispatch, mainMenu, putWelcomeResponse, renderMenuObject]);

  useEffect(() => {
    const _isSignedIn = USER_TOKEN.get().isSignedIn;
    if (_isSignedIn) {
      !mainMenu && loadMainMenu();
      setlogOutPopUp(isEmpty);
    }
  }, [isEmpty, loadMainMenu, mainMenu]);

  // useEffect(() => {
  //   mainMenu && mainMenu?.lang !== USER_TOKEN.get().lang && loadMainMenu();
  // }, [loadMainMenu, mainMenu]);

  const handleKeyDown = (event: any, item: string) => {
    switch (event?.which) {
      case 40: {
        toggleSubMenu(item);
        break;
      }
      case 38: {
        event.preventDefault();
        toggleSubMenu(item);
        break;
      }
      default:
        break;
    }
  };

  const toggleSubMenu = (item: string) => {
    const updatedMenu = { ...menuKeys };
    updatedMenu[item] = !updatedMenu[item];
    setMenuKeys(updatedMenu);
  };

  const getClassForLang = (item: string) => {
    let classNames = "";
    if (menuKeys[item]) classNames += "menu-item opened";
    else classNames += "d-none closed";

    return classNames;
  };

  const getClassNamesL2 = (item: string, subItem: string) => {
    const menu: any = { ...mainMenu };
    let classNames = "menu-item";

    if (menuKeys[item]) classNames += " opened";
    else classNames += " closed";

    const sublink = menu[item]?.subMenus[subItem].actinLink;
    const isDisabled = menu[item]?.subMenus[subItem]?.isDisabled;
    const link = menu[item]?.actinLink;
    if (link) {
      // console.log(`item: ${item} || sublink: ${sublink} || isDisabled: ${isDisabled} || link: ${link} || paths: ${paths}`);
      if (link === paths) classNames += " active";
      else if (link === "BENEFITS") {
        if (menu[item]?.isDisabled) classNames += " btn btn-link disabled text-left";
        else classNames += " active";
      }
    }

    if (sublink) {
      const eoiPath = search?.length ? paths + search : "";
      if (sublink === paths) classNames += " active";
      if (eoiPath?.length && sublink === eoiPath && item === "EOIFORMS") classNames += " active";
      else if (isDisabled) classNames += " btn btn-link disabled text-left";
    }
    return classNames;
  };

  const handleReview = (subItem: string) => {
    const { name } = pageSetting;
    dissmiss();
    console.log("handleReview: ", subItem);
    if(isMobileDevice) {
      toggleSideBar(false);
      localStorage.setItem("pageName", name);
    }
  };

  const handleWarningPopup = async (item, menulink) => {
    console.log("item :", item);

    if (item === "DEPENDENTINFORMATION" || item === "PERSONALINFORMATION" || item === "WELLNESS") {
      setWarningModal(true);
      setSelectMenuItem(item);
      setActionLink(menulink);
    } else {
      handleSelect(item);
    }
  };

  const handleSelect = (subItem: string) => {
    //Handle Click of the Menu Item
    // console.log("subItem: ", subItem);

    const callMenu = true;
    dissmiss();
    isMobileDevice && toggleSideBar(false);
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      setPageSetting(subItem, callMenu);
    } else {
      setPageSetting(subItem);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onLogoutPrompt = (e) => {
    e.preventDefault();

    if (logOutPopUp && location?.pathname !== "/" && localStorage.getItem("hideButtons") === "true") {
      if (localStorage.getItem("logOffCheck") !== "true") {
        setIsNormalLogOff(false);
      } else {
        setIsNormalLogOff(true);
      }
      handleShowModal();
    } else {
      handleSelect("LOGOFF");
      navigate("/logoff");
    }
  };

  const handleMenuButtons = (e: { preventDefault: () => void }, isDisabled: any, item: string, link: string | string[]) => {
    console.log('handleMenuButtons',item)
    if (isDisabled) {
      e.preventDefault();
    } else {
      if (item === "LOGOFF") {
        onLogoutPrompt(e);
        // navigate("/logoff");
      } else if (link === "ReviewCoverage/CurrentCoverage" || link?.includes("/ReviewCoverage/PreviousCoverage")) {
        handleReview(item);
      } else {
        handleSelect(item);
      }
      item !== "LOGOFF" && navigate(`/${link}`);
    }
  };

  //Pawan: need to verify
  const goToMainURL = (event: any, item: string) => {
    // console.log("goToMainURL: ", event, item);
    const myElement: HTMLElement | any = document.getElementById("mainHeader");
    if (!event.shiftKey && event.which === 9 && item === "LOGOFF" && isOpen) {
      console.log("shiftKey: ", event.shiftKey);
      console.log("which: ", event.which);
      console.log("item: ", item);
      myElement.setAttribute("tabIndex", "-1");
      myElement.focus();
    }
  };

  const handlePolicy = (value: any, menu: any) => {

    isMobileDevice && toggleSideBar(false);
    localStorage.setItem("policyName", pageSetting?.name || 'WELCOME');
    const section = value.actinLink.split("/");
    console.log("section: ", section);
    const menuItem = localStorage.getItem("policyName");
    navigate(`/${menuItem ? menuItem?.link : ""}`);
    const contentFooterPages: any = {};
    contentFooterPages.section = section[section.length - 1];
    contentFooterPages.display = value.display;
    sessionStorage.setItem("contentFooterPages", JSON.stringify(contentFooterPages));
    setPageSetting(menu);
  };

  const menuItems: any = { ...mainMenu };
  const keys = Object.keys(menuItems);
  const langLabels = language?.filter((key: any) => key?.keyName === "labelEN" || key?.keyName === "labelFR");
  const lblNewTab = ObjectUtils.extractLabelFromObject(footerContent, "lblNewTab");

  const handleShowHelp = (e, display, isDisabled) => {
    console.log("display: ", display);
    console.log("isDisabled: ", isDisabled);
    if (isDisabled) return "";
    else {
      const pagePath = location?.pathname === "/" || location?.pathname?.toLowerCase()?.includes("sso") ? "home" : location?.pathname.slice(1);
      let curentPage: string;

      if (pagePath.split("/").length > 1) {
        if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
          curentPage = "benefit";
        } else {
          curentPage = pagePath.split("/")[0].toLowerCase();
        }
      } else {
        curentPage = pagePath.toLowerCase() === "benefits" ? "benefit" : pagePath.toLowerCase();
      }

      // setShowHelpContent(true);
      // setHelptitle("display");

      // setHelpAriaTitle(textToRead);

      // this.setState({ showHelpContent: true, helpTitle: display, helpAriaTitle: textToRead, curentPage: curentPage });
      e.target.setAttribute("data-tid", "HelpMenu/" + curentPage);
      return "HelpMenu/" + curentPage;
    }
  };

  const handleSelectClickByWarningModal = () => {
    //Handle Click of the  warning modal yest button
    navigate(`/${actionLink}`);
    setWarningModal(false);
    handleSelect(selectMenuItem);
  };

  const handleClose = () => {
    setWarningModal(false);
    setActionLink("");
    setSelectMenuItem("");
  };

  const onConfirmLogout = () => {
    handleCloseModal();
    handleSelect("LOGOFF");
    navigate("/logoff");
  };

  
  const changeLocaleLanguage = (locale: string, location:Location<any>) => {
    USER_TOKEN.get().languageSwitchRequired && USER_TOKEN.removeQCUser();
     EventUtils.changeLocale(locale, location);
   };

  const logoutModel = {
    showModal: showModal,
    clickHandler: handleCloseModal,
    // dialogClass: "ml-addnewdependent",
    head: {
      modalTitle: lblLogoutDialogTitle.display
    },
    body: {
      confirmBoxData: {
        messagetext: logoutConfirmMessage.display,
        messagepopclass: "ml-popupmessage",
        messagetextclass: "ml-popupmessagetext"
      }
    },
    footer: {
      footerData: [
        {
          variant: "outline-secondary",
          display: btnCancel.display,
          onSubmit: handleCloseModal
        },
        isNormalLogOff && {
          variant: "secondary",
          display: btnOk.display,
          onSubmit: onConfirmLogout
        }
      ]
    }
  };

  /* const contentHelpMenu = () => {
    getContentHelpMenu(pageName || "");
  } */

  /* const helpContentModel = {
    showModal: showHelpContent,
    clickHandler: () => setShowHelpContent(!showHelpContent),
    // dialogClass: "ml-addnewdependent",
    head: {
      modalTitle: helpTitle
    },
    body: {
      bodyClass: "scroll-container",
      confirmBoxData: {
        messagetext: `<div className="container">Help</div>`,
        messagetextclass: "ml-popupmessagetext align-left"
      }
    }
  }; */

  /* const handleLinkClick = (
    event: any,
    sublink: any,
    isDisabled: any,
    handleSelect: any,
    handleReview: any,
    subItem: string
  ) => {
    event.preventDefault();
    if (!isDisabled) {
      if (sublink && sublink.includes("ReviewCoverage/CurrentCoverage")) {
        const [params] = sublink.split("?");
        if (params) localStorage.setItem("parameters", params);
        else localStorage.removeItem("parameters");
        handleReview(subItem);
      } else {
        handleSelect(subItem);
        if (sublink && sublink.includes("/ReviewCoverage/PreviousCoverage")) {
          handleReview(subItem);
          localStorage.setItem("dateString", sublink.split("?")[1].split("=")[1]);
        }
      }
      const target = isMobileDevice ? "" : "_blank";
      const newWindow = window.open(sublink, target);
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  }; */

  const updateFeaturesList = () => {
    return keys?.map((item, mIndex) => {
      const updatedMenus: any = [];
      const display = menuItems[item].display;
      // const textToRead = menuItems[item].textToRead;
      const link = menuItems[item]?.actinLink;
      const isDisabled = menuItems[item]?.isDisabled;
      const subMenus = menuItems[item].subMenus ? menuItems[item].subMenus : [];

      if (display !== undefined) {
        if (Object.keys(subMenus).length !== 0 && subMenus.constructor === Object) {
          updatedMenus.push(
            <div tabIndex={tabIndexValue} key={`nolink-${display}-${mIndex}`} role="button" onKeyDown={(event) => handleKeyDown(event, item)} className="menu-item menu-item-selected icon-action">
              {display}
              <FontAwesomeIcon className="icon-action" onClick={() => toggleSubMenu(item)} icon={menuKeys[item] ? faAngleUp : faAngleDown} />
            </div>
          );
          const subKeys = Object.keys(subMenus);

          subKeys?.map((subItem, sIndex) => {
            const subDisplay = menuItems[item].subMenus[subItem].display;
            const subTextToRead = menuItems[item].subMenus[subItem].textToRead;
            const sublink = menuItems[item].subMenus[subItem].actinLink;
            const isExternal = menuItems[item].subMenus[subItem].isExternal;
            const isDisabled = menuItems[item].subMenus[subItem].isDisabled;
            const classNameL2 = getClassNamesL2(item, subItem);
            if (subItem === "HELP") {
              updatedMenus.push(
                <div
                  data-tid=""
                  data-title={subDisplay}
                  data-class="ml-addnewdependent"
                  tabIndex={tabIndexValue}
                  key={`${subItem}-${sIndex}`}
                  role="button"
                  className={classNameL2}
                  onClick={(e) => handleShowHelp(e, subDisplay, isDisabled)}
                  onKeyDown={(e: any) => (e.key === "Enter" || e.charCode === 13) && handleShowHelp(e, subDisplay, isDisabled)}
                >
                  {subDisplay}
                </div>
              );
            } else {
              updatedMenus.push(
                isExternal && !isDisabled ? (
                  <Link key={`${subItem}-${sIndex}`} className={`${classNameL2}`} target="_blank" to={sublink} aria-label={subTextToRead + " " + lblNewTab.textToRead} rel="noopener noreferrer">
                    {subDisplay}
                    <FontAwesomeIcon className="icon-action" icon={faExternalLink} />
                  </Link>
                ) : (
                  <Link
                    key={subItem}
                    className={`${classNameL2}`}
                    onClick={
                      isDisabled
                        ? (e) => e.preventDefault()
                        : sublink?.includes("ReviewCoverage/CurrentCoverage")
                        ? () => {
                            const [, params] = sublink.split("?");
                            if (params) localStorage.setItem("parameters", params);
                            else localStorage.removeItem("parameters");
                            handleReview(subItem);
                          }
                        : () => {
                            handleSelect(subItem);
                            if (sublink?.includes("/ReviewCoverage/PreviousCoverage")) {
                              handleReview(subItem);
                              localStorage.setItem("dateString", sublink.split("?")[1].split("=")[1]);
                            }
                          }
                    }
                    // onClick={(e) => handleLinkClick(e, sublink, isDisabled, handleSelect, handleReview, subItem)}
                    to={sublink?.includes("/ReviewCoverage/PreviousCoverage") ? "/coverage" : `/${sublink}`}
                    target={sublink?.includes("ReviewCoverage/CurrentCoverage") || sublink?.includes("/ReviewCoverage/PreviousCoverage") ? (isMobileDevice ? "" : "__blank") : ""}
                  >
                    {subDisplay}
                  </Link>
                )
              );
            }
          });
        } else {
          //No submenu
          if (item === "LANGUAGESETTING") {
            updatedMenus.push(
              <div
                tabIndex={tabIndexValue}
                onKeyDown={(event) => handleKeyDown(event, item)}
                key={`nolink-${display}-${mIndex}`}
                role="button"
                className="menu-item menu-item-selected icon-action d-lg-none d-md-flex"
              >
                {" "}
                <div>{display}</div>
                <FontAwesomeIcon
                  // ss
                  className="icon-action"
                  onClick={() => toggleSubMenu(item)}
                  icon={menuKeys[item] ? faAngleUp : faAngleDown}
                />
              </div>
            );
            langLabels?.map((label: any, i: number) => {
              const languageType = label?.textToRead === "EN" ? "en" : label?.textToRead === "FR" ? "fr" : "";
              updatedMenus.push(
                <div
                  role="button"
                  tabIndex={tabIndexValue}
                  className={getClassForLang(item)}
                  aria-label={label.textToRead}
                  key={`${label.display}-${i}`}
                  onKeyDown={(e) => EventUtils.keyPressed(e) && changeLocaleLanguage(languageType, location)}
                  onClick={() => changeLocaleLanguage(languageType, location)}
                >
                  {label.display}
                </div>
              );
            });
          } else if (item === "TELUSHEALTHPRIVACY" || item === "TERMSOFUSE" || item === "ACCESSIBILITY") {
            updatedMenus.push(
              <Link
                key={`${item}-${mIndex}`}
                to="/Content/FooterContent"
                className={
                  link === pageSetting?.link
                    ? "menu-item menu-item-selected icon-action1 d-lg-none d-md-block active"
                    : isDisabled
                    ? "menu-item menu-item-selected btn btn-link icon-action1 d-lg-none d-md-block disabled"
                    : "menu-item menu-item-selected icon-action1 d-lg-none d-md-block"
                }
                onClick={isDisabled ? (e) => e.preventDefault() : () => handlePolicy(menuItems[item], item)}
              >
                {display}
              </Link>
            );
          } else if (item === "HELP") {
            updatedMenus.push(
              <div
                data-tid=""
                data-title={display}
                data-class="ml-addnewdependent"
                tabIndex={tabIndexValue}
                key={`${item}-${mIndex}`}
                role="button"
                className="menu-item menu-item-selected"
                onClick={(e) => handleShowHelp(e, display, isDisabled)}
                onKeyDown={(e: any) => (e.key === "Enter" || e.charCode === 13) && handleShowHelp(e, display, isDisabled)}
              >
                {display}
              </div>
            );
          } else if (allowWarningMessageFlag && pageSetting && pageSetting.name !== undefined) {
            updatedMenus.push(
              <Link
                tabIndex={tabIndexValue}
                key={item}
                role={item !== "LOGOFF" ? "link" : "button"}
                onClick={
                  isDisabled
                    ? (e) => e.preventDefault()
                    : (e) => {
                        item === "LOGOFF"
                          ? onLogoutPrompt(e)
                          : pageSetting?.link?.includes("Profile") || pageSetting?.link?.includes("Dependent") || pageSetting?.link?.includes("Wellness")
                          ? handleSelect(item)
                          : handleWarningPopup(item, link);
                      }
                }
                to={pageSetting && !(pageSetting?.link?.includes("Profile") || pageSetting?.link?.includes("Dependent") || pageSetting?.link?.includes("Wellness")) ? "#" : `/${link}`}
                target={link === "ReviewCoverage/CurrentCoverage" || link?.includes("/ReviewCoverage/PreviousCoverage") ? (isMobileDevice ? "" : "__blank") : ""}
                /* className={
                  link === pageSetting.link || isSelected ? "menu-item menu-item-selected active" : isDisabled ? "menu-item menu-item-selected btn btn-link disabled" : "menu-item menu-item-selected"
                } */

                className={
                  link === pageSetting.link
                    ? "menu-item menu-item-selected active"
                    : isDisabled
                    ? "menu-item menu-item-selected btn btn-link disabled"
                    : display === pageSetting.display
                    ? "menu-item menu-item-selected active"
                    : "menu-item menu-item-selected"
                }
                onKeyDown={(e) => goToMainURL(e, item)}
              >
                {" "}
                <div>{display}</div>
                {/* {(link !== pageSetting.link || display !== pageSetting.display) && !isDisabled ? <img src={Checkmark} width={18} alt="Checkmark" /> : ""} */}
              </Link>
            );
          } else {
            // console.log(`isDisabled: ${isDisabled} || pageSetting: ${JSON.stringify(pageSetting)} || link: ${link} ||  display: ${display}`);

            updatedMenus.push(
              <Link
                key={`${item}-${mIndex}`}
                role={item !== "LOGOFF" ? "link" : "button"}
                to={link?.includes("/ReviewCoverage/PreviousCoverage") ? "/coverage" : `/${link}`}
                target={link === "ReviewCoverage/CurrentCoverage" || link?.includes("/ReviewCoverage/PreviousCoverage") ? (isMobileDevice ? "" : "__blank") : ""}
                className={`menu-item menu-item-selected ${(link === pageSetting?.link || display === pageSetting?.display) && !isDisabled ? "active" : ""} ${
                  isDisabled ? "btn btn-link disabled" : ""
                }`}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleMenuButtons(e, isDisabled, item, link);
                  } else {
                    goToMainURL(e, item);
                  }
                }}
                onMouseDown={(e) => {
                  if (e.button === 0) {
                    handleMenuButtons(e, isDisabled, item, link);
                  }
                }}
              >
                <div>{display}</div>
                {/* {(display !== pageSetting?.display) && !isDisabled && localStorage.getItem("hideButtons") === "true" && item !== "LOGOFF" ? <img src={Checkmark} width={18} alt="Checkmark" /> : ""} */}
              </Link>
            );
          }
        }
      }
      return updatedMenus;
    });
  };

  return (
    <>
      {updateFeaturesList()}
      <ModalComponent modalObject={logoutModel} />
      {/* <ModalComponent modalObject={helpContentModel} /> */}
      <WarningMessageModal showWarningModal={warningModal} clickAction={handleSelectClickByWarningModal} handleClose={handleClose} />
    </>
  );
};

export default MenuComponent;
